import React from 'react';

// Components
import { Text } from '@chakra-ui/layout';
import Card from '../components/atoms/card';
import Section from '../components/molecules/section';

const About = () => {
  return (
    <Card header="Projects" type="fullscreen">
      <Section header="Sunburn">
        <Text>
          Sunburn was an internal engineering driven project at GoodLeap
          designed to solve a scalability issue with our loan application
          websites. Each website was configured for each user, and configuration
          was stored within the application itself. This meant deployments could
          take hours as each application (per user) had to be built and
          deployed. My contribution was storage of these configurations in a
          database and surfacing them through an API.
        </Text>
        <Text sx={{ marginTop: 2 }}>
          This removed 1500+ deployments per change needed in the application,
          derisked deployments by allowing easy rollbacks, and allowed the
          company to onboard users at scale with little overhead.
        </Text>
      </Section>
      <Section header="Additional Project Types" sx={{ marginTop: 4 }}>
        <Text>
          The Additional Project Types project at GoodLeap was an initiative to
          service many different home improvement projects. In order to scale
          into the many home improvement projects GoodLeap wanted to service, we
          needed a configurable way to render forms and collect data on these
          projects. My contribution to this project was designing the
          specification used for dynamic form generation, data collection, and
          APIs to deliver these configurations.
        </Text>
        <Text sx={{ marginTop: 2 }}>
          This solution has saved the company weeks of development time across
          all applications that need to collect information on home improvement
          projects. It has also given the company flexibility to support new
          home improvement projects with little engineering intervention.
        </Text>
      </Section>
      <Section header="GoodLeap Pros" sx={{ marginTop: 4 }}>
        <Text>
          GoodLeap Pros is a mobile application used by our users for servicing
          home improvement projects, this was the spearhead for the company's
          home improvement initiatives. On this project I was responsible for
          the building out the core services and client facing APIs used to
          power the application.
        </Text>
        <Text sx={{ marginTop: 2 }}>
          This project was a complete rewrite of our existing legacy APIs,
          offering more flexible and scalable resources for our clients. These
          core services would later be consumed by our external facing APIs.
        </Text>
      </Section>
    </Card>
  );
};

export default About;
